<template>
    <el-card class="box-card">
      <el-page-header
        class="product-header"
        @back="goBack"
        content="商品出库"
      ></el-page-header>
      <div class="select-container">

      </div>
    </el-card>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
      goBack() {
          this.$router.push('/homepage')
    }
  }
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.box-card {
  width: 100%;
  height: 100%;
}
</style>